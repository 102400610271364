@font-face {
  font-family: "tessanreg";
  src: url("fonts/tessanRegular.ttf");
}
@font-face {
  font-family: "tessanbold";
  src: url("fonts/tessanBold.ttf");
}
@font-face{
  font-family:"cocooutlined";
  src: url("fonts/CocogooseProOutlinedTrial.ttf")
}

@keyframes typing {
  from { width: 0% }
  to { width: 100% }
}
@keyframes typing2{
  from { width: 50%}
  to {width: 100%}
}
@keyframes cursor2{
  from, to {border-color: transparent}
  50% {border-color: blue}
}
@keyframes cursor {
  from, to { border-color: orange }
  100% { border-color: transparent; } 

}


.app {

  width: 100vw;
  height: 100vh;
}

*{
  background: #080424;

}

.center{
  display: flex;
  align-items: center;
  justify-content: center; 
}


@media screen and (min-width:1px) {
    .lrg{
      font-size: 7vw; 
    }
}


@media screen and (min-width:540) {
  .lrg{
    font-size: 6.5vw; 
  }
}

@media screen and (min-width:600px) {
  .lrg{
    font-size: 6vw; 
  }
}


@media screen and (min-width:920px) {
  .lrg{
    font-size: 2.917vw;
  }
}


.inline{
  display: inline-block;
}

@media screen and (min-width:920px) {
  .inline2{
    display:inline-block;
  }
}

@media screen and (min-width: 1px) {
  .inline2{

  }
}


.pretty{
  font-family: tessanbold;
  color:rgba(255, 255, 255, 0.754);

}

@media screen and (min-width:1px) {
  .significant{
    font-family: tessanbold;
    color: #2596be;
    ;

    font-size: 8.1vw;
  }
}

.shadow{
  border: #241304 1px solid;
}

@media screen and (min-width:920px) {
  .significant{
    font-family: tessanbold;
    color: #2596be;
    font-size: 4.1vw;
  }
}


@media screen and (min-width:1px) {
  .large{
    font-size: 11vw;
  }
}


@media screen and (min-width:500px) {
  .large{
    font-size: 9vw;
  }
}

@media screen and (min-width:920px) {
  .large{
    font-size: 5.82vw;
  }
}


li{
  display: inline;
  padding: 1.2763vw;
}
li a{
  color: white;
  text-decoration: none;
}
@media screen and (min-width: 1px){
  .medium{
    font-family: tessanbold;
    font-size: 4vw;
  }
}
@media screen and (min-width:920px){
  .medium{
    font-family: tessanbold;
    font-size: 1.89vw;
  }
}

@media screen and (min-width: 1px) {
  .small{
    font-family: tessanreg;
    font-size: 2.6vw;
  }
}
@media screen and (min-width: 920px) {
  .small{
    font-family: tessanreg;
    font-size: 1.3vw;
  }
}


@media screen and (min-width:1px) {
  .med-small {
    font-family: tessanbold;
    font-size: 2.5vw;
  }  
}

@media screen and (min-width:920px) {
  .med-small {
    font-family: tessanbold;
    font-size: 1.25vw;
  }  
}

@media screen and (min-width: 1px) {
  .info-small{
    font-family: tessanreg;
    font-size: 2.6vw;
  }
}

@media screen and (min-width: 920px) {
  .info-small{
    font-family: tessanreg;
    font-size: 1.1vw;
  }
}


@media screen and (min-width:1px) {
  .med-info {
    font-family: tessanbold;
    font-size:3vw;
  }  
}


@media screen and (min-width:920px) {
  .med-info {
    font-family: tessanbold;
    font-size: 1.25vw;
  }  
}




@media screen and (min-width: 1px) {
  .med-large{
    font-family: tessanbold;
    font-size: 8vw;
  }
}
@media screen and (min-width: 920px) {
  .med-large{
    font-family: tessanbold;
    font-size: 3.9vw;
  }
}

.pad-left{
  padding-left: 1.667vw;
}
.bold{
  font-weight: bold;
}
.margin-left{
  margin-left: 16.667vw;
}
.margin-left-s{
  margin-left:5vw;
}
@media screen and (min-width: 1px) {
  .margin-h-left{
    margin-left: 15vw;
  }
}

@media screen and (min-width: 920px) { 
.margin-h-left{
  margin-left: 30vw;
}
}

.indent-left{
  padding-left: 5%;
}
.pad-up{
  padding-bottom: 2%;
}

.pad-down{
  padding-top: 2%;
}

.locSet{
  padding-top: 10%;
  padding-left: 4%;
  padding-right: 100%;

}
@media screen and (min-width: 1px) {
  .grid{
   
  }
  
}

@media screen and (min-width: 920px) {
  .grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows:  1fr;
  }
  
}


@media screen and (min-width: 1px) {
  .grid2{
    display: grid;
    grid-template-columns: 0.11fr;
    grid-template-rows:  0.1fr 0.1fr;
  }
  
}

@media screen and (min-width: 920px) {
  .grid2{
    display: grid;
    grid-template-columns: 0.1fr 0.1fr;
    grid-template-rows:  0.1fr;
  }
  
}






.rounded{
  border-radius: 5%;
}

@media screen and (min-width: 1px) {
  .circular{
    font-size: 2.8vw;
    color:white;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(37,150,190,1) 0%, rgba(251,31,55,1) 100%);;
    border-style: none;
    transition: transform .2s;
    border-radius: 50%;
    display: inline-block;
    width: 3vw;
    height: 5.277vw;
    text-align: center;
    transition: transform .2s;
  }
}

@media screen and (min-width:920px) {
  .circular{
    font-size: 1.4vw;
    color:white;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(37,150,190,1) 0%, rgba(251,31,55,1) 100%);;
    border-style: none;
    transition: transform .2s;
    border-radius: 50%;
    display: inline-block;
    width: 7vw;
    height: 5.277vw;
    text-align: center;
    transition: transform .2s;
  }
}

.stretched{
  background: linear-gradient(-90deg, rgba(2,0,36,1) 0%, rgba(37,150,190,1) 0%, rgba(251,31,55,1) 100%);;
  border-style: none;
  transition: transform .2s;

  display: inline-block;
  width: 4vw;
  height: 0.5277vw;
  
}
.circular:hover{
  transform:  scale(1.3);
}


@media screen and (min-width: 1px) {
  .input-pretty{
    font-family: tessanbold;
  outline: none; 
  background-color: #100847; 
  font-size: 3vw;
  color:bisque;
  border-style: none;
  transition-property: none;
  padding: 2%;
  height: 6vw;
  }
}

@media screen and (min-width: 920px) {
  .input-pretty{
    font-family: tessanbold;
    outline: none; 
    background-color: #100847; 
    font-size: 1.2vw;
    color:bisque;
    border-style: none;
    transition-property: none;
    padding: 2%;
    height: 3vw;
  }
}

.text-gradient{
  background: linear-gradient(to right, #2596be, #fb1f37);

  background-position: 0% 0%;
  background-size: 300% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: hue 15s infinite linear;
  animation: grad 5s infinite;
  
}

.text-gradient-blue{
  background: linear-gradient(to right, #2596be, #252abe );

  background-position: 0% 0%;
  background-size: 300% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: hue 15s infinite linear;
  animation: grad 5s infinite;
  
}


.text-gradient-red{
  background: linear-gradient(to right, #f5929d, rgb(252, 0, 29) );

  background-position: 0% 0%;
  background-size: 300% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: hue 15s infinite linear;
  animation: grad 5s infinite;
  
}
@keyframes grad  {
  0% {background-position: 0% 0%;}
  50% {background-position: 100% 100%;}
  100% {background-position: 0% 0%;}

}
@media screen and (min-width: 1px) {
  .button-pretty{
    font-size: 2.66vw;
    color:white;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(37,150,190,1) 0%, rgba(251,31,55,1) 100%);;
    padding-left: 7%;
    padding-right: 7%;
    padding-top: 1.5%;
    padding-bottom: 1.5%; 
    margin-top: 4%;
    border-style: none;
    transition: transform .2s;
    margin-bottom: 5%;
    
  }
}
@media screen and (min-width: 920px) {
  .button-pretty{
    font-size: 1.333vw;
    color:white;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(37,150,190,1) 0%, rgba(251,31,55,1) 100%);;
    padding-left: 7%;
    padding-right: 7%;
    padding-top: 1%;
    padding-bottom: 1%; 
    margin-top: 4%;
    border-style: none;
    transition: transform .2s;
    margin-bottom: 5%;
    
  }
}



.button-pretty:disabled{
  opacity: 30%;

}
.button-pretty:disabled:hover{
  transform: scale(0.8);
}

.button-pretty:hover{
  transform:  scale(1.3);

}
.indent-top{
  padding-top: 10%;
}
.pad{
  padding: 2%;
}

.padL{
  padding: 1%;
}

.type{
  overflow: hidden;
 /* border-right: 0.5em solid orange; 
  border-top-right-radius: 90%; */

  white-space: nowrap; 
  margin: 0 auto; 
  letter-spacing: .125vw; 
  animation: 
    typing 3s steps(40, end),
    blink-caret .2s step-end infinite;

  animation: typing, cursor;
  animation-duration: 2s;

}
.type2{
  overflow: hidden;
  border-right: 0.125vw solid #2596be;

  white-space: nowrap; 
  margin: 0 auto; 
  letter-spacing: .125vw; 

  animation: 
    typing 3s steps(50, end),
    cursor 3s steps(30, end),
    blink-caret .2s step-end infinite;


}



.underline{
  border-bottom: solid 0.208vw white;
  border-radius: 10%;
}

@media screen and (min-width:1px) {
  .underline-curve{
    border-bottom: solid 0.3vw white;
    border-bottom-right-radius: 20%;
  }
  }
@media screen and (min-width:920px) {
  .underline-curve{
    border-bottom: solid 0.104vw white;
    border-bottom-right-radius: 80%;
  }
}
.inputURL{
  font-size: large;
  color: white

}
.submit{
  color: white;
  border-radius: 5%;
  font-size: large;
}

.prg{
  color: white;

}

.custom{

}

.custom::placeholder{
  color:white;
  text-shadow: #080424;
  text-align: center;
}
.custom::-webkit-outer-spin-button,
.custom::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


@media screen and (min-width:1px) {
  .icon{
    height: 150px;
    width:150px;
  }
}

@media screen and (min-width:920px) {
  .icon{
    height: 200px;
    width:200px
  }
}


@media screen and (min-width:1px) {  
  .titleInvalid{
    margin-left: 18%;
  }
}

@media screen and (min-width:920px) {  
  .titleInvalid{
    margin-left: 48%;
  }
}

@media screen and (min-width:1px) {  
  .titleButton{
    margin-left: 9%;
  }
}

@media screen and (min-width:920px) {  
  .titleButton{
    margin-left: 43%;
  }
}

@media screen and (min-width:1px) {
  .videoInput{
    width:50%; 
    height:7vw;
    margin-bottom:1.2%;
    scale:130%;
  }
}

@media screen and (min-width:920px) {
  .videoInput{
    width:25%; 
    height:3.5vw;
    margin-bottom:1.2%;
  }
}


@media screen and (min-width:1px) {
  .videoButton{
    scale:130%;
    z-index: 1000;
    margin-left: 10%;
  }
}

@media screen and (min-width:920px) {
  .videoButton{
    margin-left: 6.3%;
  }
}


.fade {
  animation: fadein 0.75s linear forwards;
}


@keyframes fadein {
  0% {
    opacity: 0;
    filter: blur(10px)
  }
  100% {
    opacity: 1;
    filter: blur(0px)

  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
    filter: blur(10px)
  }
  100% {
    opacity: 0;
    filter: blur(0px)

  }
}

.warn{
 color: rgb(236, 86, 86);
}




.default-btn-style {
  position: relative;
  border: 2px solid transparent;
  border-radius: 91px;
  background: linear-gradient(black, black);
  color: white;
  background-clip: padding-box;

}

.default-btn-style::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, rgb(0, 224.4, 255, 0.3), rgb(0, 117.3, 255, 0.3));
  border-radius: 91px;
  opacity: 0.5;
  transition: all 0.4s;
}

.default-btn-style:hover:before{
  opacity: 1;
}

.info{
  background-color: rgba(61, 58, 58, 0.487);

  margin-left: 18%;
  margin-right: 18%;
}
.gray{
  background-color: rgba(61, 58, 58, 0.487);

}


.m-0{
  margin: 0%;
}